@use '../../../../styles/colors.scss';

#casaAIFloatingButton {
  $position: 2.5rem;

  position: fixed;
  right: $position;
  bottom: $position;
  aspect-ratio: 1;
  width: 3.5rem;
  z-index: 1000;
  cursor: pointer;
  transition: transform ease-in-out 300ms;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    width: 100% !important;
    height: 100% !important;
  }

  &.active {
    right: calc($position + 15px);
  }
}
